.empty-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    
    opacity: 0.7;
    text-align: center;
    padding-inline: 1rem;
}

.empty-container .icon{
    font-size: 5rem;
}

.empty-container h1{
    padding:1rem;
    margin:0;
}


