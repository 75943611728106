html{
    font-family: 'Lato';
    user-select: none;
    cursor: pointer;
}

img{
    -webkit-user-drag: none;
}

