/* Animation keyframes */

@keyframes sideReveal {
    0%{
        min-width: 20px;
    }
    100%{
        min-width: 100px;
    }
}
@keyframes textReveal {
    0%{
        font-size: 0rem;
    }
    100%{
        font-size: 2rem;
    }
}

@keyframes sideHide {
    0%{
        min-width: 100px;
    }
    100%{
        min-width: 20px;
    }
}
@keyframes contentSlide {
    
    100%{
        transform: translateX(-640px);
        opacity: 0;
    }
}
@keyframes editSlide {
    0%{
        opacity: 0;
        transform: translateX(640px);
    }
    100%{
        opacity: 1;
        transform: translateX(0px);
    }
}


.empty-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;

    opacity: 0.7;
    text-align: center;
    padding-inline: 1rem;
}

.empty-container img{
    width: 5rem;
}

.empty-container h1{
    padding:1rem;
    margin:0;
}

.empty-container a{
    color: black;
}

.cookie-grouper{
    margin: 1rem;
    margin-top: 1rem;
    margin-bottom: 8rem;
}

.cookie-container{
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 1rem;
    border-radius: 10px;
    overflow: hidden;
    justify-content: space-between;
    max-width: 40rem;
    margin-inline: auto;
}
.img-tag-date-container{
    display: flex;
    min-width: 8rem;
    max-width: 8rem;
    flex-direction: column;
    margin-left: 0.2rem;
    margin-top:0.2rem;
}
.img-tag-date-container img{
    position: relative;
    width: 100%;
    height: 10rem;
    object-fit: cover;
    border-radius: 5px;
}

.cookie-info{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    flex-grow: 1;
    margin-right: 20px;
}
.cookie-info h1{
    font-size: 1.2rem;
}
.cookie-info p{
    font-size: 1rem;
}
.tag-date-container{
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    flex-wrap: wrap;
    padding: 0.4rem;
}
.tag-date-container .tag{
    border-radius: 5px 5px 0px 0px;
    padding: 0.3rem;
    flex-grow: 1;
    text-align: center;
}
.tag-date-container .date{
    background-color: hsla(0, 0%, 100%, 0.39);
    border-radius: 0px 0px 5px 5px;
    padding: 0.3rem;
    flex-grow: 1;
    text-align: center;
}
.cookie-side-rank{
    position: absolute;
    right: 0;
    height: 100%;
    min-width: 20px;
    box-shadow: inset 1px 0px 10px #00000013;
}

.cookie-side-rank-expanded{
    position: absolute;
    right: 0;
    height: 100%;
    min-width: 100px;
    box-shadow: inset 1px 0px 10px #00000013;
    display: flex;
    flex-direction: column;
    animation: sideReveal 0.3s ease-out;
}

.cookie-side-rank.hide{
    animation: sideHide 0.2s ease-out
}

.cookie-side-rank-expanded .icon{
    color: rgba(0, 0, 0, 0.589);
    opacity: 0.8;
    font-size: 2rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: textReveal 0.3s ease-out;
}

.cookie-side-rank-expanded .icon:active{
    background-color: rgba(0, 0, 0, 0.199);
}
.cookie-side-rank-expanded .hr{
    height: 1px;
    background-color: rgb(0, 0, 0);
    opacity: 0.2;
}

/* Cookie Edit Mode */
.img-edit-container{
    position: relative;
    width: 100%;
    height: 10rem;
    object-fit: cover;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.img-edit-container img{
    position: absolute;
    z-index: 0;
    filter: blur(1px);
    opacity: 0.7;
}

.img-edit-container .icon{
    color: rgb(255, 255, 255);
    z-index: 1;
    font-size: 2rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: textReveal 0.3s ease-out;
    filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.63));
}

.img-edit-container .icon:active{
    background-color: rgba(255, 255, 255, 0.308);
    filter: none;
}
.tag-date-container.edit .tag:active, .tag-date-container.edit .date:active {
    opacity: 0.4;
}

.cookie-info.edit h1, .cookie-info.edit p{
    background-color: rgba(0, 0, 0, 0.055);
    border-radius: 5px;
}
.cookie-info.edit h1:active, .cookie-info.edit p:active{
    opacity: 0.5;
}

.edit-side-container{
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
}

.edit-confirm{
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgb(0, 0, 0);
    border-radius: 50% 0px 0px 0px;
    box-shadow: -1px -1px 5px rgba(0, 0, 0, 0.164);
}
.edit-cancel{
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgb(0, 0, 0);
    border-radius: 0px 0px 0px 50%;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.164);
}
.edit-rank{
    position: absolute;
    right: 0;
    background-color: rgb(0, 0, 0);
    border-radius: 100% 0 0 100%;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.164);
}

.edit-confirm:active{
opacity: 0.5;
}
.edit-cancel:active{
    opacity: 0.5;
}
.edit-rank:active{
    opacity: 0.5;
}

.edit-confirm .icon{
    padding: 0.8rem;
    padding-right: 0.5rem;
    font-size: 2rem;
    color:white;
}
.edit-cancel .icon{
    padding: 0.8rem;
    padding-right: 0.5rem;
    font-size: 2rem;
    color:white;
}
.edit-rank .icon{
    padding: 0.5rem;
    padding-right: 0.5rem;
    font-size: 2rem;
    color:white;
}

/* Applying animation to the content of the cookie */
.edit-content-animation{
    animation: contentSlide 0.2s ease-in forwards;
}
/* Applying animation to the edited mode of the cookie */
.edit-mode-animation{
    animation: editSlide 0.5s ease-out forwards;
}

/* When the user clicks on a specific input while on edit mode */
.edit-overlay-container{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.781);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.edit-overlay-container span{
    font-size: 1.5rem;
    color: white;
    text-align: center;
    margin: 0.5rem;
}
.edit-overlay-container textarea{
    font-size: 1rem;
    padding: 1rem;
    box-sizing: border-box;
    width: 65%;
    height: 100%;
    margin-bottom: 1rem;
    font-family: 'Lato';
    resize: none;
    caret-color:#ffb656;
    border-radius: 5px;
}
.edit-overlay-container textarea:focus{
    outline: none;
}
.edit-overlay-container .cancel{
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgb(255, 255, 255);
    border-radius: 0px 0px 0px 50%;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.164);
}

.edit-overlay-container .cancel .icon{
    padding: 0.8rem;
    padding-right: 0.5rem;
    font-size: 2rem;
    color:rgb(0, 0, 0);
}

.date-input, .select{
    color: black;
}
.date-input:focus, .select:focus{
    outline: none;
}
.toolbar-container{
    padding-inline: 1rem;
}
.toolbar{
    background-color: rgba(255, 255, 255, 0.788);
    max-width: 40rem;
    margin: auto;
    margin-top: 1rem;
    border-radius: 10px;
    padding: 1rem;
    box-sizing: border-box;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.034);
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    flex-wrap: wrap;
    align-items: center;
}
.toolbar .search{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
}
.toolbar .search .icon{
    margin-right: 0.5rem;
    font-size: 1rem;
}
.toolbar input{
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.13);
    font-size: 1rem;
    padding: 0.5rem;
    width: 100%;
}
.toolbar input:focus{
    outline: none;
}
.toolbar .toolbar-icon{
    border-radius: 5px;
    padding: 0.5rem;
}

.toolbar-icon-click{
    border-radius: 5px;
    padding: 0.5rem;
    color: white;
    margin-inline: 0.1rem;
    background-color:  rgba(0, 0, 0, 0.432);
}
.toolbar-icon-click.active{
    border-radius: 5px;
    padding: 0.5rem;
    color: white;
    margin-inline: 0.1rem;
    background-color:  rgba(0, 0, 0, 0.74);
}

.cookie-group-tag{
    background-color: rgba(255, 255, 255, 0.912);
    padding: 1rem;
    border-radius: 10px;
    max-width: 40rem;
    margin: auto;
    margin-top: 1rem;
    box-sizing: border-box;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.034);
}
.cookie-group-tag.collapsed{
    overflow: hidden;
    height: 4.3rem;
}

.cookie-group-title{
    font-size: 2rem;
    font-weight: bold;
}
.cookie-group-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cookie-group-head .icon{
    font-size: 2.5rem;
}
.cookie-group-length{
    font-size: 1.5rem;
    margin-right: 0.5rem;
    background-color: black;
    border-radius: 5px;
    padding: 0.2rem;
    color: white;
}
