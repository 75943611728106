body{
    background-color: rgb(228, 228, 228);
}

.navbar_container{
    background-image: linear-gradient(white,rgb(236, 236, 236));
    width: 100%;
    position: fixed;
    bottom: 0px; 
    display: flex;
    justify-content: space-evenly;
    padding-block: 0.4rem;
    padding-bottom: 2rem;
    z-index: 2;
}

.navbar_btn{
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    color: #9b9b9b;
    display: grid;
    row-gap: 0.25rem;
    justify-content: center;
    width: 20vw;
}



.navbar_btn .icon{
    margin: auto;
}

.navbar_text{
    font-weight: bold;
    color: #9b9b9b;
    font-size: 0.8rem;
    
}




.navbar_btn:active{
    opacity: 0.8;  
}

.navbar_btn.selected{
    color: #3a3a3a;
}

.navbar_btn.selected.trophy{
    color: #ffae44;
}
.navbar_btn.selected.cookies{
    color: #b87b58;
}
.selected .navbar_text {
    color: #3a3a3a;
}





