
.add_form{
    display: flex;
    flex-direction: column;
}

.add_form .text_form{
    padding: 1rem;
    font-size: 1rem;
    margin: 0;
    border-radius: 0px;
    border: 1px solid rgba(0, 0, 0, 0.062);

}

.add_form .description{
    border-top: 0px;
    padding-bottom: 5rem;
}

.input-group
{
    position: relative;
    
}
.input
{
    padding: 1.5rem;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #0000002f;
    font-size: 1rem;
    margin: 0px;
    border-radius: 0;
    resize: none;
    font-family: 'Lato';
}
.placeholder
{
    position: absolute;
    top: 25px;
    left: 0px;
    font-size: 1rem;
    padding-left: 10px;
    color: #818181;
    transition: 0.1s ease-out;
    pointer-events: none;
    box-sizing: border-box;
}
.input:focus + .placeholder, .input:not(:placeholder-shown) + .placeholder
{
    top: 1px;
    padding-top: 5px;
    padding-bottom: 2px;
    color: #818181;
    background-color: #fff;
    font-size: 0.8rem;
    width: calc(100% - 2px);
    margin-left: 1px;
}

.input-container{
    background-color: rgb(255, 255, 255);
    border: 1px solid #0000002f;
    padding: 1rem;
    display: flex;
    justify-content:space-between;
}

.input-container .icon, .tags-container .icon, .img-container .icon{
    font-size: 1.5rem;
    width: 2rem;
    padding-inline: 0.5rem;
    color: #3a3a3a;
}

.input-container .label, .tags-container .label, .img-container .label{
    
    padding-inline: 0.5rem;
}

.img-container .label, .img-container .icon{
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.63));
    color: white;
}

.datepicker{
    width: 4rem;
    text-align: center;
}

.rank-container{
    background-color: rgb(255, 255, 255);
    border: 1px solid #0000002f;
    padding: 1rem;
}

.input-container select, .input-container select:focus, .input-container input{
    color: #3a3a3a;
    outline: #3a3a3a;
}

.tags-container{
    background-color: rgb(255, 255, 255);
    border: 1px solid #0000002f;
    padding: 1rem;
}
.tags-container .lineOne{
    display: flex;
    justify-content:space-between;
}
.lineOne button{
    background-color:transparent;
    border: none;
    padding: 0;
}
.lineTwo{
    display: flex;
    flex-wrap: wrap;
}

.tag-input{
    background-color: #f0f0f0;
    border-radius: 5px;
    padding: 0.5rem;
    margin: 0.2rem;
    border: none;
    font-size: 1rem;
    text-align: center;
}
.tag-input.active{
    background-color: #ffb656;
}
.tag-input:focus{
    border: none;
    outline: none;
}
.tag-input:focus, .tag-input:active{
    background-color: #ffb656;
    caret-color: #855b26;
}


input, textarea{
    caret-color:#ffb656;
}
.button-container{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}
.button-container button{
    background-color: #ffb656;
    color: black;
    border: 1px solid #f0aa4f;
    padding: 1rem;
    font-size: 1rem;
    width: 100%;
    border-radius: 3px;
    margin-bottom: 6rem;
}

.button-container button:active{
    opacity: 0.7;
}

.img-container{
    background-color: rgb(255, 255, 255);
    border-top: 1px solid #0000002f;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    padding: 2rem;
    overflow: hidden;
    position: relative;
    z-index: 0;
    
}

.img-container img{
    opacity: 0.9;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100%;
    filter: blur(5px);
    z-index: -1;
}

.img-container:active{
    opacity: 0.5;
}
.img-overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.img-overlay img{
    object-fit: scale-down;
    width: 70vw;
    max-width: 640px;
    margin-inline: auto;
}
.img-overlay .icon{
    color: rgb(255, 255, 255);
    font-size: 2rem;
    padding: 1rem;
    filter: drop-shadow(0px 0px 2px rgb(0, 0, 0));
    cursor: pointer;
}
.img-overlay .icon:active{
    opacity: 0.5;
}



